import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Home from './screen/home/Home';
import Login from './screen/login/Login';
import NoPage from './screen/NoPage';
import Header from './component/header/Header';
import reportWebVitals from './reportWebVitals';

function Main() {
  return (
    <div class='root_view'>
      <div class='container_main'>
        <Header class="header" />
        <Routes class="content">
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </div>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
