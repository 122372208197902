import React from 'react';
import { useState, useRef, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import iconAddImageSvg from '../../assets/images/add_photo.svg';
import imageSelectDone from '../../assets/images/image_select_done.svg';
import CallApi from '../../network/CallApi';
import Spinner from 'react-bootstrap/Spinner';


const Home = () => {
    let isSelectFace = true;
    const inputOriginalImageRef = useRef(null);
    const [faceImage, setFaceImage] = useState(null);
    const [originalImage, setOriginalImage] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    const [originalBase64, setOriginalBase64] = useState(null);
    const [faceBase64, setFaceBase64] = useState(null);

    const navigate = useNavigate();

    const getKeyReface = async () => {
        if (showLoading == false) {
            if ((faceImage != null && originalImage != null)) {
                setShowLoading(true)
                try {
                    const postData = { original_image: originalBase64, face_image: faceBase64, apikey: 'swapvip1' };
                    const postResponse = await CallApi.postFormData('https://nhaccutrangan.com/api/reface/v1/', postData);
                    const jsonObject = JSON.parse(postResponse);
                    console.log("=================>>>postResponse: ", typeof (jsonObject));
                    console.log("=================>>>code: ", jsonObject.code);
                    if (jsonObject.code === 200) {
                        setResultImage(jsonObject.data.image);
                        setShowLoading(false)
                        console.log("=================>>>postResponse: ", postResponse);
                    } else {
                        setShowLoading(false)
                        alert("Có lỗi xảy ra, Vui lòng thử lại1")
                    }
                } catch (error) {
                    setShowLoading(false)
                    alert("Có lỗi xảy ra, Vui lòng thử lạ2")
                }
            } else {
                alert("Please select the image")
            }
        }
    }

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedImage = e.target.files[0];
            console.log("============selectedImage name: ", selectedImage.name);
            const base64Image = await convertFileToBase64(selectedImage);
            if (isSelectFace) {
                setFaceImage(selectedImage);
                setFaceBase64(base64Image);
            } else {
                setOriginalImage(selectedImage);
                setOriginalBase64(base64Image);
            }
        } else {
            console.log("No file selected.");
            // Thêm các xử lý khác nếu cần
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    function formatImageName(imageName) {
        if (imageName.length <= 20) {
            return imageName;
        } else {
            const firstPart = imageName.substring(0, 14);
            const extensionIndex = imageName.lastIndexOf('.');
            const extension = imageName.substring(extensionIndex);
            return `${firstPart}...${extension}`;
        }
    }

    return (
        <div className="container_home">
            <div className="top_home">

            </div>

            <div className="body_home">
                <input class='input_hide' ref={inputOriginalImageRef} type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
                <div class='main_select_image'>
                    <div
                        onClick={() => {
                            if (showLoading == false) {
                                isSelectFace = true
                                inputOriginalImageRef.current.click()
                            }
                        }}
                        class='main_select_face'>
                        <img
                            class={'image_select'}
                            src={faceImage ? imageSelectDone : iconAddImageSvg} />

                        <text class='text_title_select_image'>{faceImage ? formatImageName(faceImage.name) : 'Select face image'}</text>
                    </div>


                    <div onClick={() => {
                        if (showLoading == false) {
                            isSelectFace = false
                            inputOriginalImageRef.current.click()
                        }
                    }}
                        class='main_select_face'>
                        <img
                            class={'image_select'}
                            src={originalImage ? imageSelectDone : iconAddImageSvg} />

                        <text class='text_title_select_image'>{originalImage ? formatImageName(originalImage.name) : 'Select original image'}</text>
                    </div>


                    <div
                        class='button_reface'
                        onClick={() => getKeyReface()}
                        style={{ opacity: (faceImage && originalImage) ? 1 : 0.5 }}>
                        <Spinner
                            style={{ display: showLoading ? '' : 'none' }}
                            size="sm" animation="border" role="status" variant='light'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <text class='text_title_select_image'>Start reface</text>
                    </div>

                </div>

                <img
                    class='image_result'
                    style={{ display: resultImage ? 'flex' : 'none' }}
                    src={`data:image/png;base64,${resultImage}`} />

            </div>
        </div>
    )
}

export default Home;