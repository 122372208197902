class CallApi {
    // Phương thức GET
    static async get(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Request failed!');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    static async postFormData(url, data) {
        try {
            const formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            const requestOptions = {
                method: "POST",
                body: formData,
                redirect: "follow"
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Request failed!');
            }
            return await response.text();
        } catch (error) {
            console.error('Error posting FormData:', error);
            throw error;
        }
    }
}

export default CallApi;